import { Box, Button, FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { ChangeEvent, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Page } from "../../components/Page";
import { Body1, Body2 } from "../../components/Typography";
import { useAppDispatch } from "../../state/configureStore";
import {
    fetchCustomer,
    selectCustomer,
    updateCopyOrderMats,
} from "../../state/slices/customerSlice";
import { openDeliveryInfoDialog } from "../../state/slices/dialogsSlice";
import { selectDeliveryDate } from "../../state/slices/orderDetailsSlice";
import {
    getOrderMatForWeek,
    selectSelectedWeek,
    setWeek,
    toNextWeek,
    toPreviousWeek,
} from "../../state/slices/orderMatsSlice";
import { selectFoodToGoFilter, selectFoodToGoProducts } from "../../state/slices/productSelectors";
import { filterByType, searchProduct } from "../../state/slices/productsSlice";
import { FilterType, ProductType } from "../../state/types";
import { Cart } from "./cart/Cart";
import { ProductTables } from "./product-table/ProductTables";
import { ProductSearch } from "./ProductSearch";
import { FilterButton } from "./tabs/FilterButton";

const useStyles = makeStyles({
    lastWeekLegend: {
        marginRight: "2rem",
    },
});

export const OrderMatPage: FC = () => {
    const { t } = useTranslation("order");
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const customer = useSelector(selectCustomer);
    const deliveryDate = useSelector(selectDeliveryDate);
    const filter = useSelector(selectFoodToGoFilter);
    const products = useSelector(selectFoodToGoProducts);
    const selectedWeek = useSelector(selectSelectedWeek);

    useEffect(() => {
        if (!deliveryDate) {
            dispatch(openDeliveryInfoDialog({ canClose: false }));
        }
    }, [dispatch, deliveryDate]);

    useEffect(() => {
        if (deliveryDate && !selectedWeek) {
            const dd = DateTime.fromISO(deliveryDate);
            dispatch(setWeek({ weekYear: dd.weekYear, weekNumber: dd.weekNumber }));
        }
    }, [dispatch, deliveryDate, selectedWeek]);

    useEffect(() => {
        if (!selectedWeek) return;
        void dispatch(getOrderMatForWeek(selectedWeek));
    }, [dispatch, selectedWeek]);

    const handleUpdateCopyOrderMats = async (
        _: ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        await dispatch(updateCopyOrderMats(checked));
        await dispatch(fetchCustomer());
        if (!selectedWeek) return; // Should never be undefined here
        await dispatch(getOrderMatForWeek(selectedWeek));
    };

    return (
        <Page>
            <Cart orderMatCart />

            <Box display="flex" flexDirection="row" justifyContent="end">
                <ProductSearch onChange={productName => dispatch(searchProduct(productName))} />
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <FilterButton
                        onClick={() =>
                            dispatch(
                                filterByType({
                                    productType: ProductType.FoodToGo,
                                    filterType: FilterType.all,
                                }),
                            )
                        }
                        highlight={filter === FilterType.all}
                    >
                        {t("filter.showAllProducts")}
                    </FilterButton>
                    <FilterButton
                        onClick={() =>
                            dispatch(
                                filterByType({
                                    productType: ProductType.FoodToGo,
                                    filterType: FilterType.favorites,
                                }),
                            )
                        }
                        highlight={filter === FilterType.favorites}
                    >
                        {t("filter.showFavorites")}
                    </FilterButton>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Button onClick={() => dispatch(toPreviousWeek())}>{"<"}</Button>
                    <Body1 bold>
                        {selectedWeek?.weekNumber} ({selectedWeek?.weekYear})
                    </Body1>
                    <Button onClick={() => dispatch(toNextWeek())}>{">"}</Button>
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mt="1rem"
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={customer?.copyOrderMats}
                            onChange={handleUpdateCopyOrderMats}
                            color="primary"
                        />
                    }
                    label={t("productTable.copyOrderMats")}
                />
                <Body2 className={classes.lastWeekLegend}>{t("productTable.lastWeek")}</Body2>
            </Box>

            <ProductTables
                showFavorite
                showOrderMat
                productType={ProductType.FoodToGo}
                products={products}
                filter={filter}
            />
        </Page>
    );
};
