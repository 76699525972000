import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { DateTime } from "luxon";
import { Endpoint } from "../../network/endpoints";
import { get, post } from "../../network/restClient";
import { RootState } from "../configureStore";
import { ProductType } from "../types";
import { selectTimeZone } from "./customerSlice";
import { selectDeliveryDate } from "./orderDetailsSlice";
import { selectProductEntities } from "./productsSlice";

type OrderMatRow = {
    productId: string;
    deliveryDate: string;
    quantity: number;
    quantityLastWeek: number;
};

export const updateOrderMatProduct = createAsyncThunk<
    unknown,
    { productId: string; deliveryDate: string; quantity: number }
>("orderMat/updateProduct", async update => post(Endpoint.orderMat, update));

export const getOrderMatForWeek = createAsyncThunk<OrderMatRow[], Week>(
    "orderMat/getOrderMatForWeek",
    async params =>
        get<OrderMatRow[]>(Endpoint.orderMat, {
            params: { year: params.weekYear, week: params.weekNumber },
        }),
);

export type Week = { weekYear: number; weekNumber: number };

type State = {
    selectedWeek?: Week;
    orderMat: OrderMatRow[];
};

const initialState: State = {
    orderMat: [],
};

const orderMatsSlice = createSlice({
    name: "orderMat",
    initialState,
    reducers: {
        setWeek: (state, { payload }: PayloadAction<Week>) => {
            state.selectedWeek = payload;
        },
        toPreviousWeek: state => {
            if (!state.selectedWeek) return;
            const previous = DateTime.fromObject(state.selectedWeek).minus({ week: 1 });
            state.selectedWeek = {
                weekYear: previous.weekYear,
                weekNumber: previous.weekNumber,
            };
        },
        toNextWeek: state => {
            if (!state.selectedWeek) return;
            const next = DateTime.fromObject(state.selectedWeek).plus({ week: 1 });
            state.selectedWeek = {
                weekYear: next.weekYear,
                weekNumber: next.weekNumber,
            };
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(getOrderMatForWeek.fulfilled, (state, { payload }) => {
            state.orderMat = payload;
        });
    },
});

export const { setWeek, toPreviousWeek, toNextWeek } = orderMatsSlice.actions;

export const selectSelectedWeek = (state: RootState) => state.orderMats.selectedWeek;

const selectOrderMatRows = (state: RootState) => state.orderMats.orderMat;

export const selectOrderMat = createSelector(
    selectOrderMatRows,
    selectTimeZone,
    (rows, timeZone) => {
        const grouped = _.groupBy(
            rows,
            row =>
                `${row.productId}-${
                    DateTime.fromISO(row.deliveryDate, { zone: timeZone }).weekday
                }`,
        );
        return (productId: string, weekday: number) => {
            const key = `${productId}-${weekday}`;
            return grouped[key] ?? [];
        };
    },
);

export const selectOrderMatProducts = createSelector(
    selectDeliveryDate,
    selectOrderMatRows,
    selectProductEntities,
    (deliveryDate, orderMatRows, products) =>
        orderMatRows
            .filter(
                row =>
                    deliveryDate &&
                    row.deliveryDate <= deliveryDate &&
                    DateTime.fromISO(row.deliveryDate).weekday ===
                        DateTime.fromISO(deliveryDate).weekday,
            )
            .flatMap(row => {
                const product = products[row.productId];
                if (!product) return [];
                return {
                    ...product,
                    salesUnitQuantity: row.quantity,
                    type: ProductType.FoodToGo,
                };
            }),
);

export const orderMatsReducer = orderMatsSlice.reducer;
