import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Endpoint } from "../../network/endpoints";
import { get } from "../../network/restClient";
import { RootState } from "../configureStore";
import { DeliveryDate } from "../types";
import { selectSelectedWeek } from "./orderMatsSlice";

export const fetchDeliveryDates = createAsyncThunk("fetch-delivery-dates", async () => {
    return get<DeliveryDate[]>(Endpoint.deliveryDates);
});

type State = {
    loading: boolean;
    error?: string;
};

const adapter = createEntityAdapter<DeliveryDate>({
    selectId: entity => entity.deliveryDate,
});

const slice = createSlice({
    name: "delivery-dates",
    initialState: adapter.getInitialState<State>({
        loading: false,
    }),
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchDeliveryDates.pending, state => {
            state.loading = true;
            state.error = undefined;
        });
        addCase(fetchDeliveryDates.fulfilled, (state, action) => {
            state.loading = false;
            adapter.setAll(state, action.payload);
        });
        addCase(fetchDeliveryDates.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error.message;
        });
    },
});

export const { selectAll: selectAllDeliveryDates, selectById: selectDeliveryDateById } =
    adapter.getSelectors<RootState>(state => state.deliveryDates);

export const selectDeliveryDatesError = (state: RootState) => state.deliveryDates.error;

export const selectDeliveryDatesLoading = (state: RootState) => state.deliveryDates.loading;

export const selectDeliveryDatesByWeek = createSelector(
    selectAllDeliveryDates,
    selectSelectedWeek,
    (_: RootState, assortmentIds: number[]) => assortmentIds,
    (deliveryDates, selectedWeek, assortmentIds) => {
        if (!selectedWeek) return [];
        return deliveryDates.filter(dd => {
            const ddDateTime = DateTime.fromISO(dd.deliveryDate);
            return (
                dd.assortments.some(a => assortmentIds.includes(a.assortmentId)) &&
                ddDateTime.weekYear === selectedWeek.weekYear &&
                ddDateTime.weekNumber === selectedWeek.weekNumber
            );
        });
    },
);

export const deliveryDatesReducer = slice.reducer;
